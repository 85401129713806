<template>
  <div class="mx-2 my-3">
    <div class="flex flex-row w-full bg-grey-light-3 py-1 pl-2 rounded">
      <div class="flex flex-row items-center focus:outline-none">
        <p class="text-sm-2 text-grey-light">STATUS</p>
      </div>
      <button
        class="w-1/3 flex flex-row items-center focus:outline-none"
        @click="sortCol('NAME')"
      >
        <p class="text-sm-2 text-grey-light mr-0.4">FULL NAME</p>
        <img
          src="../../../../assets/img/delta-table/caret.svg"
          class="transition-all"
          :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
        />
      </button>
      <button
        class="flex flex-row items-center w-1/6 justify-center focus:outline-none"
        @click="sortCol('POSITION')"
      >
        <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE</p>
        <img
          src="../../../../assets/img/delta-table/caret.svg"
          class="transition-all"
          :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
        />
      </button>
      <button
        class="flex flex-row items-center w-1/4 justify-center focus:outline-none"
        @click="sortCol('ROLE')"
      >
        <p class="text-sm-2 text-grey-light mr-0.4">JOB ROLE</p>
        <img
          src="../../../../assets/img/delta-table/caret.svg"
          class="transition-all"
          :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
        />
      </button>
      <button
        class="flex flex-row items-center w-1/6 justify-center focus:outline-none"
        @click="sortCol('STATUS')"
      >
        <p class="text-sm-2 text-grey-light mr-0.4">ASSESSMENT STATUS</p>
        <img
          src="../../../../assets/img/delta-table/caret.svg"
          class="transition-all"
          :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null"
        />
      </button>
    </div>
    <div v-for="item in tableData" :key="Math.random() + item.name">
      <TableItem :item="item" />
      <div class="border border-grey-light-1 border-b-0" />
    </div>
  </div>
</template>

<script>
import TableItem from "./TableItem";

export default {
  name: "Table",
  props: ["tableData"],
  components: {
    TableItem,
  },
  data: () => ({
    rotationMatrix: [1, 1, 1, 1],
    table: [],
  }),
  methods: {
    sortCol(col) {
      if (col !== this.col) {
        this.way = 1;
        this.col = col;
        this.rotationMatrix = [1, 1, 1, 1];
      } else {
        this.way *= -1;
        switch (col) {
          case "NAME":
            this.rotationMatrix[1] *= -1;
            break;
          case "POSITION":
            this.rotationMatrix[2] *= -1;
            break;
          case "ROLE":
            this.rotationMatrix[3] *= -1;
            break;
          case "STATUS":
            this.rotationMatrix[4] *= -1;
            break;
          default:
            break;
        }
      }
      switch (col) {
        case "NAME":
          if (this.way === -1) return this.tableData.reverse();
          return this.tableData.sort((a, b) => a.name.localeCompare(b.name));
        case "POSITION":
          if (this.way === -1) return this.tableData.reverse();
          return this.tableData.sort((a, b) =>
            a.position.localeCompare(b.position)
          );
        case "ROLE":
          if (this.way === -1) return this.tableData.reverse();
          return this.tableData.sort((a, b) =>
            a.jobTitle.localeCompare(b.jobTitle)
          );
        case "STATUS":
          if (this.way === -1) return this.tableData.reverse();
          return this.tableData.sort((a, b) =>
            a.status.localeCompare(b.status)
          );
      }
    },
  },
  mounted() {
    this.table = this.sortCol("STATUS");
  },
};
</script>

<style></style>
