<template>
  <div class="flex flex-row w-full py-2 pl-2">
    <div class="w-42 flex justify-center mr-0.5 mt-0.4">
      <div class="w-10 h-10 rounded-full" :class="statusColor" />
    </div>
    <div class="w-1/3">
      <p class="text-md text-grey-dark-1 font-bold">
        {{ item.name }}
      </p>
    </div>
    <div class="flex w-1/6 justify-center">
      <p class="text-md text-grey-dark-2">
        {{ item.position }}
      </p>
    </div>
    <div class="flex w-1/4 justify-center">
      <p class="text-md text-grey-dark-2">{{ item.jobRole }}</p>
    </div>
    <div class="flex flex-row items-center w-1/6 justify-center">
      <p
        class="border ml-2 rounded text-sm-2 p-0.5 text-blue align-middle"
        :class="status.class"
      >
        {{ status.text }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "TableItem",
  props: {
    item: { type: Object, default: null },
  },
  computed: {
    status() {
      switch (this.item.status) {
        case "COMPLETED":
          return {
            text: "Completed",
            class: "text-green-2 border-green-2",
          };
        case "NOT_STARTED":
          return {
            text: "Not Started",
            class: "text-red border-red",
          };
        case "IN_PROGRESS":
          return {
            text: "In Progress",
            class: "text-blue border-blue",
          };
      }
    },
    statusColor() {
      switch (this.item.userStatus) {
        case 0:
          return 'bg-red';
        case 1:
          return 'bg-green-2';
        case 2:
          return 'bg-yellow-medium';
        case 3:
          return 'bg-grey-medium-2';
        case 4:
          return 'bg-purple';
        default:
          break;
      }
    }
  },
};
</script>
