<template>
  <div class="bg-white shadow mt-3 p-3 rounded mt-2">
    <h2 class="text-grey-light text-mdl font-bold font-headers mt-1 mb-2">
      Team Engagement
    </h2>
    <div class="mb-2">
      <p class="text-mdh text-grey-dark-1 font-bold">Participants statuses:</p>
      <div class="flex space-x-0.5 items-center">
        <div class="w-10 h-10 bg-yellow-medium rounded-full" />
        <p class="text-mdh text-grey-dark-1">
          <span class="text-yellow-medium">Invited</span> = users that have been
          sent emails to join the platform
        </p>
      </div>
      <div class="flex space-x-0.5 items-center">
        <div class="w-10 h-10 bg-green-2 rounded-full" />
        <p class="text-mdh text-grey-dark-1">
          <span class="text-green-2">Active</span> = registered users
        </p>
      </div>
      <div class="flex space-x-0.5 items-center">
        <div class="w-10 h-10 bg-red rounded-full" />
        <p class="text-mdh text-grey-dark-1">
          <span class="text-red">Deactivated</span> = user accounts that have
          been deactivated by admins
        </p>
      </div>
      <div class="flex space-x-0.5 items-center">
        <div class="w-10 h-10 bg-purple rounded-full" />
        <p class="text-mdh text-grey-dark-1">
          <span class="text-purple">Deleted</span> = user accounts that have
          personal information removed
        </p>
      </div>
    </div>
    <div class="flex justify-between items-center mx-2">
      <div>
        <p class="text-md">
          Showing {{ this.teamProgress.length }} Participants
        </p>
      </div>
      <div class="w-1/4">
        <Select
          :options="this.filters"
          :onSelect="setValue"
          label="Filter by Status"
          objects
        />
      </div>
    </div>
    <div>
      <Table :tableData="teamProgress" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Table from "./Table";

export default {
  name: "TeamEngagement",
  components: {
    Table,
  },
  data: () => ({
    filter: {
      label: "All statuses",
      value: "ALL",
    },
    filters: [
      {
        label: "All statuses",
        value: "ALL",
      },
      {
        label: "Not started",
        value: "NOT_STARTED",
      },
      {
        label: "In progress",
        value: "IN_PROGRESS",
      },
      {
        label: "Completed",
        value: "COMPLETED",
      },
    ],
  }),
  methods: {
    setValue(val) {
      this.filter = val;
    },
  },
  computed: {
    ...mapGetters({
      filteredTeam: "teamResults/filteredTeam",
    }),
    teamProgress() {
      return this.filteredTeam(this.filter.value);
    },
  },
};
</script>
